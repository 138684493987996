import { Fragment, useState, useEffect } from "react";

export function renderLineBreaks(str) {
  return str.split("<br />").map((line, i) => (
    <Fragment key={i}>
      {line} <br />
    </Fragment>
  ));
}

// from https://stackoverflow.com/questions/42538968/javascript-uk-phone-number-beautifier
export const formatPhoneNumber = (no) => {
  return no.replace(/\s+/g, "").replace(/(.)(\d{4})(\d)/, "+44 ($1)$2 $3");
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
export function htmlEntities(str) {
  return String(str)
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;");
}
