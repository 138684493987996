import styled from "styled-components";
import Contact from "./Contact";
import { EmailIcon, LinkedInIcon, PhoneIcon } from "./SVGs";
import { formatPhoneNumber } from "./utils";

const ContactSection = ({ contactInfo, setShowContactSlideOut }) => {
  return (
    <Container>
      <div className="inner">
        <div className="">
          <Contact setShowContactSlideOut={setShowContactSlideOut} />
        </div>
        <Social>
          <div className="contact-info">
            {contactInfo.phone_number && (
              <a
                className="contact-link phone"
                rel="noreferrer"
                target="_blank"
                href={`tel:${contactInfo.phone_number}`}
              >
                <PhoneIcon />{" "}
                <span className="text">
                  {formatPhoneNumber(contactInfo.phone_number)}
                </span>
              </a>
            )}
            {contactInfo.email && (
              <a
                className="contact-link email"
                rel="noreferrer"
                target="_blank"
                href={`mailto:${contactInfo.email}`}
              >
                <EmailIcon /> <span className="text">{contactInfo.email}</span>
              </a>
            )}
            {contactInfo.linkedin_url && (
              <a
                className="contact-link linkedin"
                rel="noreferrer"
                target="_blank"
                href={contactInfo.linkedin_url}
              >
                <LinkedInIcon /> <span className="text">LinkedIn</span>
              </a>
            )}
          </div>
        </Social>
      </div>
    </Container>
  );
};
export default ContactSection;

const Container = styled.section`
  margin-top: 4em;
  background: linear-gradient(
    45deg,
    rgba(234, 79, 79) 20%,
    rgba(63, 131, 219) 80%
  );
  display: flex;

  .inner {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    @media (max-width: 900px) {
      flex-direction: column;
    }

    > div {
      flex: 1 1 50%;
      h2 {
        text-align: center;
        color: #fff;
      }
    }
  }

  .contact-info {
    font-size: 1.5em;
    color: #fff;
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 7.5rem auto 1rem auto;
    > a {
      display: flex;
      align-items: center;
      margin: 0.5em 0;
      color: #fff;
      text-decoration: none;
    }

    .text {
      padding-left: 1em;
      @media (max-width: 500px) {
        padding-left: 0;
      }
    }

    svg {
      fill: #fff;
      width: 2em;
      height: 2em;
    }
  }

  .contact-link {
    @media (max-width: 500px) {
      flex-direction: column;
    }
  }
`;

const Social = styled.div``;
