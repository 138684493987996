import { motion } from "framer-motion";
import { Fragment } from "react";
import styled from "styled-components";
import { renderLineBreaks } from "./utils";

const bannerVars = {
  hidden: {
    opacity: 0,
    y: 30,
  },
  show: {
    opacity: 1,
    y: 0,
  },
};

const TopBanner = ({ banner, setShowContactSlideOut }) => {
  return (
    <Container
      variants={bannerVars}
      background={banner && banner.background_image.sizes.large}
      initial="hidden"
      animate="show"
    >
      <div className="inner">
        <div className="col left">
          <h2>{banner.title}</h2>
          <div className="content">{renderLineBreaks(banner.content)}</div>
        </div>
        <div className="col buttons right">
          {/* {Object.keys(banner.buttons).map((id) => {
            const button = banner.buttons[id];
            return (
              button.text && (
                <Button key={id} href={button.url}>
                  {button.text}
                </Button>
              )
            );
          })} */}
          <Button href="#video">Watch a Demo</Button>
          <Button onClick={() => setShowContactSlideOut(true)}>
            Contact Us
          </Button>
        </div>
      </div>
      <div className="gradient-underlay"></div>
    </Container>
  );
};

export default TopBanner;

const Container = styled(motion.section)`
  width: 100%;
  height: 700px;
  background-image: url(${(props) => props.background});
  background-size: cover;
  position: relative;
  @media (max-width: 700px) {
    height: unset;
    max-width: 700px;
  }

  .gradient-underlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: linear-gradient(
      45deg,
      rgb(234 77 77),
      rgb(234 77 77 / 50%),
      rgb(62 130 218 / 50%),
      rgb(62 130 218)
    );
    z-index: 0;
  }

  .inner {
    z-index: 2;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: stretch;
    color: #fff;
    font-size: 1.25em;
    max-width: 1400px;
    margin: 0 auto;
    @media (max-width: 750px) {
      flex-direction: column;
    }
  }

  .left {
    padding: 1em 5em 1em 1em;
  }

  .right {
    padding: 1em 1em 1em 5em;
    @media (max-width: 1000px) {
      padding: 1em;
    }
  }

  .col {
    flex: 1 1 auto;
    margin-top: 50px;
    @media (max-width: 800px) {
      padding: 2em 5px;
      text-align: center;
    }
  }
  .buttons {
    display: flex;
    flex-direction: column;
    @media (max-width: 750px) {
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }

  h2 {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.25em;
  }
`;

const Button = styled.a`
  white-space: nowrap;
  background: #ea4f4f;
  padding: 1em 2.5em;
  border-radius: 0.5em;
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  text-align: center;
  margin: 1em 0.5em;
  cursor: pointer;
`;
