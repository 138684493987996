import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { renderLineBreaks } from "./utils";

const FeaturedVideo = ({ video, offsetTop }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.3 });
  const videoRef = useRef(null);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
      if (videoRef.current && videoRef.current.paused) {
        videoRef.current.play();
      }
    } else {
      if (videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
      }
    }
  }, [controls, inView]);

  return (
    <Container
      ref={ref}
      animate={controls}
      initial="hidden"
      // transition={{ duration: 0.3 }}
      variants={{
        hidden: { opacity: 0, scale: 1 },
        visible: { opacity: 1, scale: 1 },
      }}
      offsetTop={offsetTop}
    >
      <motion.div
        className="video"
        initial="hidden"
        animate={controls}
        transition={{ delay: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -100 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <a id="video"></a>
        <div className="inner">
          <video ref={videoRef} controls src={video.video}></video>
        </div>
      </motion.div>
      <motion.div
        className="content"
        animate={controls}
        initial="hidden"
        transition={{ delay: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: 100 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div className="inner">
          <h3>{video.title}</h3>
          {renderLineBreaks(video.content)}
        </div>
      </motion.div>
    </Container>
  );
};

export default FeaturedVideo;

const Container = styled(motion.section)`
  display: flex;
  overflow: hidden;
  position: relative;
  @media (max-width: 1150px) {
    flex-direction: column;
  }
  #video {
    display: block;
    position: absolute;
    /* padding-top: ${(props) => props.offsetTop * 4}px; */
    top: ${(props) => props.offsetTop * -1}px;
    visibility: hidden;
    pointer-events: none;
  }

  .video {
    flex: 1 1 66%;
    position: relative;
    .inner {
      padding-bottom: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
      position: relative;
      width: 100%;
      height: 100%;
      top: 0;
    }
    video {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    flex: 1 1 33%;
    background: rgb(234, 79, 79);
    background: linear-gradient(
      45deg,
      rgba(234, 79, 79, 1) 20%,
      rgba(63, 131, 219, 1) 80%
    );

    text-align: center;
    color: #fff;
    padding: 4em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .inner {
      max-width: 450px;
    }
  }
`;
