import { motion } from "framer-motion";
import styled from "styled-components";
import { renderLineBreaks } from "./utils";

const containerVars = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.1,
    },
  },
};

const boxVars = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const InfoBoxes = ({ infoboxes }) => {
  return (
    <Container variants={containerVars} initial="hidden" animate="visible">
      {Object.keys(infoboxes).map((id, i) => {
        const box = infoboxes[id];
        if (box.title || box.content) {
          return (
            <InfoBox variants={boxVars} key={id}>
              <div className="inner">
                <h3>{box.title}</h3>
                <div className="content">{renderLineBreaks(box.content)}</div>
              </div>
              {Object.keys(infoboxes).length > i + 1 && (
                <motion.span className="divider"></motion.span>
              )}
            </InfoBox>
          );
        }
        return;
      })}
    </Container>
  );
};

export default InfoBoxes;

const Container = styled(motion.section)`
  display: flex;
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
  margin: 10em auto;

  @media (max-width: 950px) {
    flex-direction: column;
    align-items: center;
  }

  .divider {
    width: 5px;
    min-width: 5px;
    max-width: 5px;
    margin: 0.5em 2em;
    background: #3f83db;
    border-radius: 5px;
  }
`;

const InfoBox = styled(motion.div)`
  flex: 1 1 30%;
  margin: 2em ​auto;
  display: flex;
  @media (max-width: 950px) {
    flex-direction: column;

    .inner {
      max-width: 650px;
    }
  }
`;
