// styles/index.js
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  *, *:before, *:after  {
    box-sizing: border-box;
    font-weight: 200;
    font-style: normal;
    font-family: 'Poppins',Helvetica, sans-serif;
  }

  html,body {
    margin: 0;
    padding: 0;
    font-size: 15px;
    scroll-behavior: smooth;

  }

  h1,h2,h3 {
    font-family: 'IBM Plex Sans', sans-serif;
  }

  h1, h2 {
    font-size: 2.25rem;
    font-weight: 600;
    text-transform: uppercase;
}


  h3 {
    font-size: 2em;
    margin: 0.5em;
    text-transform: uppercase;
  }

  i, .fa, .far, .fas {
    font-family: Font Awesome\ 5 Free;
  }
  /* Scroll Bar */

    /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d8d8d8;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }

  .container {
    max-width: 800px;
    margin: 0 auto;
  }
`;
