import { useEffect } from "react";
import styled from "styled-components";

const Error = ({ error, errors, removeError }) => {
  useEffect(() => {
    setTimeout(() => {
      removeError(error.id);
    }, 5000);
  }, []);
  return <Container>{error.message}</Container>;
};

export default Error;

const Container = styled.div`
  background: #fff;
  padding: 0.5em;
  margin: 0.5em 0;
  color: #ea4f4f;
  font-weight: 400;
  border-radius: 0.5em;
`;
