import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import { InfoIcon } from "./SVGs";
import useWindowDimensions, { renderLineBreaks } from "./utils";

const TeamMembers = ({ team }) => {
  const [selected, setSelected] = useState(false);
  const controls = useAnimation();
  const [ref, inView] = useInView({ threshold: 0.7 });
  const containerRef = useRef();
  const { width } = useWindowDimensions();

  // useEffect(() => {
  //   console.log([containerRef.current]);
  // });

  useEffect(() => {
    if (inView) {
      // alert("inVuew");
      controls.start("visible");
    }
  }, [controls, inView]);

  const isEven = (num) => {
    if (num % 2 == 0) return true;
    else return false;
  };

  return (
    <Container
      ref={ref}
      bioWidth={
        containerRef.current ? containerRef.current.clientWidth : "800px"
      }
      animate={controls}
      initial="hidden"
      variants={{
        hidden: { opacity: 0 },
        visible: {
          opacity: 1,
          transition: {
            delayChildren: 0.5,
            staggerChildren: 0.1,
          },
        },
      }}
    >
      <h2>The Team</h2>

      <div className="members">
        <div className="container-inner" ref={containerRef}>
          {team.map((member, i) => {
            return (
              <Member
                isEven={isEven(i)}
                selected={i === selected}
                key={i}
                variants={{
                  hidden: { opacity: 0, y: -20 },
                  visible: { opacity: 1, y: -0 },
                }}
                animate={{
                  top: i === selected ? "3em" : 0,
                  scale: i === selected ? 1.1 : 1,
                }}
              >
                <div className="image-container-outer">
                  <div className="image-container-inner">
                    <img
                      src={member.profile_picture}
                      alt={member.name}
                      className="profile"
                    />
                    <InfoIcon onClick={() => setSelected(i)} />
                  </div>
                </div>
                <div className="name">
                  <h3>{member.name}</h3>
                  <div className="background"></div>
                </div>
                {selected === i && width < 999 && (
                  <div className="bio">
                    {renderLineBreaks(team[selected].bio)}
                  </div>
                )}
              </Member>
            );
          })}
        </div>
        <AnimatePresence>
          {team[selected] && width > 999 && (
            <div className="bio">{renderLineBreaks(team[selected].bio)}</div>
          )}
        </AnimatePresence>
      </div>
    </Container>
  );
};

export default TeamMembers;

const Container = styled(motion.section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .container-inner {
    display: flex;
    max-width: none;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  .members {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bio {
    background: #eaeaea;
    width: ${(props) => props.bioWidth}px;
    width: calc(${(props) => props.bioWidth}px - 3.2em);
    padding: 1em;
    @media (max-width: 1000px) {
      width: 100%;
      max-width: 750px;
      margin: 0 auto 2em auto;
    }
  }
`;

const Member = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em;
  position: relative;
  opacity: ${(props) => (!props.selected ? 0.5 : 1)};

  h3 {
    margin: 0 0.5em 1em 0.25em;
  }

  .image-container-outer {
    background: linear-gradient(
      45deg,
      rgba(234, 79, 79, 1) 20%,
      rgba(63, 131, 219, 1) 80%
    );
    border-radius: 100%;
    padding: 0.75em;
    &:hover img.profile {
      transform: scale(1.1);
    }
  }

  .image-container-inner {
    width: 220px;
    height: 220px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      z-index: 1;
      width: 4em;
      height: 4em;
      pointer-events: none;
      opacity: 0;
      transform: scale(0.5);
      transition: 0.2s;
      fill: #f5f5f5;
      cursor: pointer;
    }

    &:hover {
      img.profile {
        opacity: 0.5;
      }
      svg {
        transform: scale(1);
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  img.profile {
    position: absolute;
    width: 100%;
    height: 100%;
    /* border: 8px solid #ea4f4f; */
    display: block;
    object-fit: cover;
    transform: scale(1);
    transition: 0.2s;
  }

  .name {
    position: relative;
    display: flex;
    justify-content: center;
    .background {
      background: ${(props) => (props.selected ? "#eaeaea" : "#f5f5f5")};
      position: absolute;
      width: 100%;
      width: calc(100% + 2rem);
      height: 100%;
      height: calc(100% + 4rem);
      top: -5.5rem;

      transform: ${(props) =>
        props.isEven ? "rotate(1deg)" : "rotate(-1deg)"};
      z-index: -1;
    }
  }
`;
